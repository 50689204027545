import React, { CSSProperties, FunctionComponent } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { Loader } from 'rs-emd-ui-atoms';
import { useAccountPageQuery } from '../../../generated/graphql';
import { MyAccountNavbar } from '../../../rs-emd-ui-modules/src/components/user';
import { RedirectWithStatus } from '../../../rs-emd-ui-modules/src/helpers/redirect-with-status.component';
import { IContextInfo } from '../../../rs-emd-ui-modules/src/models/master/context-info.model';
import { MyAccountRouter } from './my-account-router-component';
import styles from './my-account-wrapper-component.module.scss';

type MyAccountWrapperProps = {
  contextInfo: IContextInfo;
  isInLoggedInHomepage?: boolean;
};

export const MyAccountWrapper: FunctionComponent<MyAccountWrapperProps> = (props: MyAccountWrapperProps) => {
  const { search, pathname } = useLocation();

  //retrieve logged-in homepage cms preview's argument
  const loggedInHomepagePreviewPageId = new URLSearchParams(search).get('loggedInHomepagePreviewPageId');

  const { loading, error, data } = useAccountPageQuery({
    variables: { isInLoggedInHomepagePreview: loggedInHomepagePreviewPageId !== null },
    ssr: false,
  });

  if (loading) {
    //do not show loader when in logged-in homepage
    return props.isInLoggedInHomepage ? <></> : <Loader />;
  } else if (error || !data) {
    return <RedirectWithStatus to={process.env.REACT_APP_ERROR_ENDPOINT} statusCode={500} />;
  } // logged out?
  else if (!data.account.isSuccess) {
    if (pathname.toLowerCase().endsWith('forgotpassword')) return <RedirectWithStatus to={'/user/forgotPassword'} statusCode={302} />;
    //if whilst loading the my account area in the logged in homepage, the user's session expires, redirect again to homepage
    else if (props.isInLoggedInHomepage) return <Redirect to={'/'} key={Math.random()} />;
    else return <RedirectWithStatus to={`/user/login?redirectTo=${pathname}`} statusCode={302} />;
  } else
    return (
      <div style={{ width: '100%' } as CSSProperties}>
        <MyAccountNavbar data={data} isInLoggedInHomepage={props.isInLoggedInHomepage} />
        <div className={styles.content}>
          <MyAccountRouter contextInfo={props.contextInfo} isInLoggedInHomepage={props.isInLoggedInHomepage} />
        </div>
      </div>
    );
};

MyAccountWrapper.defaultProps = { isInLoggedInHomepage: false };

export default MyAccountWrapper;
