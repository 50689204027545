import {
  GetPricesByQtyDocument,
  GetPricesByQtyQuery,
  GetPricesByQtyQueryVariables,
  GetPricesDocument,
  GetPricesQuery,
  GetPricesQueryVariables,
} from '../generated/graphql';
import { IPDPProductPricing } from '../rs-emd-ui-modules/src/models/pdp/pdp-product-pricing.model';
import { IPricingService } from '../rs-emd-ui-modules/src/services/pricing/pricing-service';
import {
  IPricesByQtyRequest,
  IPricesByQtyResult,
  IPricesRequest,
  IPricesResult,
} from '../rs-emd-ui-modules/src/services/pricing/pricing.dto';
import { graphQLService } from './graphql-service';

export class PricingService implements IPricingService {
  async getPrices(variables: IPricesRequest): Promise<IPricesResult> {
    const result = await graphQLService.apolloClient.query<GetPricesQuery, GetPricesQueryVariables>({
      query: GetPricesDocument,
      variables: { stockCode: variables.stockCode },
    });

    let res: IPricesResult = {};
    if (result.data) {
      res.productPrices = result.data.productListPage.productPrices;
    }
    return res;
  }

  async getPricesByQty(variables: IPricesByQtyRequest): Promise<IPricesByQtyResult> {
    const result = await graphQLService.apolloClient.query<GetPricesByQtyQuery, GetPricesByQtyQueryVariables>({
      query: GetPricesByQtyDocument,
      variables: { stockCode: variables.stockCode, quantity: variables.quantity },
    });

    let res: IPricesByQtyResult = {};
    if (result.data) {
      res.priceArea = result.data.productDetailPage.priceArea as IPDPProductPricing;
    }
    return res;
  }
}

export const pricingService = new PricingService();
