import loadable from '@loadable/component';

// Compare Alternatives
const CompareAlternatives = loadable(() => import(/* webpackChunkName: "compare-alternatives" */ './compare-alternatives-component'), {
  resolveComponent: (components) => components.CompareAlternatives,
});

const CompareAlternativesProductHeader = loadable(
  () =>
    import(
      /* webpackChunkName: "compare-alternatives" */ './compare-alternative-product-header/compare-alternatives-product-header-component'
    ),
  {
    resolveComponent: (components) => components.CompareAlternativesProductHeader,
  }
);

export { CompareAlternatives, CompareAlternativesProductHeader };
