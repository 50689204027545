import React, { FunctionComponent } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { Loader } from 'rs-emd-ui-atoms';
import { useRegistrationPageQuery } from '../../generated/graphql';
import { sanitizeRelativeUrl } from '../../rs-emd-ui-modules/src';
import { RegistrationPage } from '../../rs-emd-ui-modules/src/components/user';
import { RedirectWithStatus } from '../../rs-emd-ui-modules/src/helpers/redirect-with-status.component';
import { IContextInfo, Locale } from '../../rs-emd-ui-modules/src/models/master/context-info.model';
import { customerService } from '../../services/customer-service';

type RegistrationPageWrapperProps = {
  contextInfo: IContextInfo;
  onboarding?: boolean;
};

export const RegistrationWrapper: FunctionComponent<RegistrationPageWrapperProps> = (props: RegistrationPageWrapperProps) => {
  let location = useLocation();

  const redirectionURL = new URLSearchParams(location.search).get('redirectTo');
  const token = new URLSearchParams(location.search).get('token');

  const { loading, error, data } = useRegistrationPageQuery({
    variables: { token: token ?? undefined },
    ssr: true,
    skip: props.onboarding && !token,
    fetchPolicy: 'no-cache',
  });

  if (props.onboarding && !token) {
    return <RedirectWithStatus to='' statusCode={302} />;
  } else if (loading) {
    return <Loader />;
  } else if (error || !data) {
    return <RedirectWithStatus to={process.env.REACT_APP_ERROR_ENDPOINT} statusCode={500} />;
  }
  // if user is already logged in
  else if (!data.registrationPage.isSuccess) {
    if (redirectionURL) return <Redirect to={sanitizeRelativeUrl(redirectionURL)} />;
    return <Redirect to='/user/myaccount' />; //go to overview page
  }

  return (
    <RegistrationPage
      data={data.registrationPage}
      isIndia={props.contextInfo.locale === Locale.In}
      onboarding={props.onboarding}
      customerService={customerService}
      redirectionURL={redirectionURL ? sanitizeRelativeUrl(redirectionURL) : undefined}
    />
  );
};

export default RegistrationWrapper;
