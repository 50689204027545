export interface IGetContextInfo {
  master: { contextInfo: IContextInfo };
}
export interface IContextInfo {
  locale: Locale;
  isBot: boolean;
}

export enum Locale {
  Core = 'CORE',
  Ru = 'RU',
  Lv = 'LV',
  Lt = 'LT',
  Ly = 'LY',
  Cy = 'CY',
  Ee = 'EE',
  Fi = 'FI',
  Il = 'IL',
  Ro = 'RO',
  Mt = 'MT',
  Gr = 'GR',
  Tr = 'TR',
  In = 'IN',
  Ae = 'AE',
  Int = 'INT',
  Hr = 'HR',
  Ua = 'UA',
  Sa = 'SA',
  Az = 'AZ',
  Export = 'EXPORT',
  Cl = 'CL',
  Sk = 'SK',
  Br = 'BR',
  Id = 'ID',
  Kz = 'KZ',
  Af = 'AF',
  Eg = 'EG',
  Bh = 'BH',
  Bg = 'BG',
  Om = 'OM',
  Ma = 'MA',
}
