import { FunctionComponent, MouseEvent, default as React } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Button, Icon } from 'rs-emd-ui-atoms';
import { useUser, useUserDispatch } from '../../../../../components/app/user-context';
import { loginService } from '../../../../../services/login-service';
import { clickEvent } from '../../../../../tagging/ensighten';
import { getLabel } from '../../../helpers/html.utils';
import { ILabel } from '../../../models/common/label.model';
import styles from './account-menu-component.module.scss';

type AccountMenuProps = {
  labels?: ILabel[];
  className: string;
  onCloseMenuClick: (e?: MouseEvent<HTMLDivElement> | MouseEvent<HTMLAnchorElement>) => void;
};

export const AccountMenu: FunctionComponent<AccountMenuProps> = (props: AccountMenuProps) => {
  const user = useUser();
  const userDispatch = useUserDispatch();
  const location = useLocation();
  const history = useHistory();

  return (
    <>
      <div className={`${styles['account-menu-container']} ${styles[props.className]}`}>
        {!user.customer.isLoggedIn && (
          <div className={styles['cta-container']}>
            <Button
              id='loginDropdown'
              text={getLabel(props.labels, 'log_in')}
              href={`/user/login?redirectTo=${location.pathname}`}
              onClick={(e?: MouseEvent<HTMLDivElement> | MouseEvent<HTMLAnchorElement>) => onClick('login', e)}
            />
            <Button
              id='signUpDropdown'
              buttonType='outline'
              href={`/user/register?redirectTo=${location.pathname}`}
              onClick={(e?: MouseEvent<HTMLDivElement> | MouseEvent<HTMLAnchorElement>) => onClick('signup', e)}>
              {getLabel(props.labels, 'sign_up')}
            </Button>
          </div>
        )}
        <div className={styles['menu-container']}>
          <Link
            id='overviewDropdown'
            to={`/user/myaccount`}
            onClick={(e?: MouseEvent<HTMLDivElement> | MouseEvent<HTMLAnchorElement>) => onClick('overview', e)}>
            <Icon name='home'></Icon>
            <span>{getLabel(props.labels, 'overview')}</span>
            <Icon name='chev-right' className={styles.chev}></Icon>
          </Link>
          {!user.customer.isPunchout && (
            <Link
              id='ordersDropdown'
              to={`/user/myaccount/orders`}
              onClick={(e?: MouseEvent<HTMLDivElement> | MouseEvent<HTMLAnchorElement>) => onClick('orders', e)}>
              <Icon name='parcel'></Icon>
              <span>{getLabel(props.labels, 'orders')}</span>
              <Icon name='chev-right' className={styles.chev}></Icon>
            </Link>
          )}
          <Link
            id='partslistDropdown'
            to={`/user/myaccount/partslists`}
            onClick={(e?: MouseEvent<HTMLDivElement> | MouseEvent<HTMLAnchorElement>) => onClick('partslist', e)}>
            <Icon name='star-outline'></Icon>
            <span>{getLabel(props.labels, 'parts_list')}</span>
            <Icon name='chev-right' className={styles.chev}></Icon>
          </Link>
          {!user.customer.isPunchout && (
            <>
              <Link
                id='profileDropdown'
                to={`/user/profile`}
                onClick={(e?: MouseEvent<HTMLDivElement> | MouseEvent<HTMLAnchorElement>) => onClick('profile', e)}>
                <Icon name='account'></Icon>
                <span>{getLabel(props.labels, 'profile')}</span>
                <Icon name='chev-right' className={styles.chev}></Icon>
              </Link>
              <Link
                id='resetPasswordDropdown'
                to={`/user/forgotPassword`}
                onClick={(e?: MouseEvent<HTMLDivElement> | MouseEvent<HTMLAnchorElement>) => onClick('reset', e)}>
                <Icon name='unlocked'></Icon>
                <span>{getLabel(props.labels, 'reset_password')}</span>
                <Icon name='chev-right' className={styles.chev}></Icon>
              </Link>
            </>
          )}
        </div>
        {user.customer.isLoggedIn && !user.customer.isPunchout && (
          <div className={`${styles['cta-container']}`}>
            <Button id='logoutDropdown' buttonType='outline' href={`/user/register?redirectTo=${location.pathname}`} onClick={logout}>
              {getLabel(props.labels, 'logout')}
            </Button>
          </div>
        )}
      </div>
    </>
  );

  function logout() {
    props.onCloseMenuClick();
    loginService.logout(userDispatch).then(() => history.push('/user/login?logout=1'));
    onClickEvent('logout');
  }

  function onClick(
    type: 'login' | 'signup' | 'overview' | 'orders' | 'partslist' | 'profile' | 'reset' | 'logout',
    event?: MouseEvent<HTMLDivElement> | MouseEvent<HTMLAnchorElement>
  ) {
    props.onCloseMenuClick(event);
    onClickEvent(type);
  }

  function onClickEvent(type: 'login' | 'signup' | 'overview' | 'orders' | 'partslist' | 'profile' | 'reset' | 'logout') {
    const eventData = { __Info: '' };
    switch (type) {
      case 'login':
        eventData.__Info = 'Login from My Account dropdown';
        break;
      case 'signup':
        eventData.__Info = 'Sign up from My Account dropdown';
        break;
      case 'overview':
        eventData.__Info = 'Overview from My Account dropdown';
        break;
      case 'orders':
        eventData.__Info = 'Orders from My Account dropdown';
        break;
      case 'partslist':
        eventData.__Info = 'Parts List from My Account dropdown';
        break;
      case 'profile':
        eventData.__Info = 'Profile from My Account dropdown';
        break;
      case 'reset':
        eventData.__Info = 'Reset password from My Account dropdown';
        break;
      case 'logout':
        eventData.__Info = 'Logout from My Account dropdown';
        break;
    }
    clickEvent(eventData);
  }
};
